<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="validateAndSubmit">
      <md-card class="md-layout-item md-size-30 md-small-size-100">
        <md-card-header>
          <div class="md-title">Login</div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('userId')">
                <label for="User ID">User ID</label>
                <md-input name="user-id" id="user-id" v-model="userId" :disabled="sending"/>
                <span class="md-error" v-if="!$v.password.userId">User ID is required</span>
              </md-field>
            </div>
          </div>

          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('password')">
                <label for="password">Password</label>
                <md-input
                  name="password"
                  id="password"
                  type="password"
                  v-model="password"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.password.required">Password is required</span>
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending"/>

        <md-card-actions>
          <md-button type="submit" class="md-primary" :disabled="sending">Login</md-button>
        </md-card-actions>
      </md-card>

      <md-snackbar :md-active.sync="success">Login successful!</md-snackbar>
      <md-snackbar :md-active.sync="wrongCredentials">Invalid user name or password!</md-snackbar>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],

  data: () => ({
    userId: '',
    password: '',
    sending: false,
    success: false,
    wrongCredentials: false
  }),

  validations: {
    userId: {
      required
    },
    password: {
      required
    }
  },

  methods: {
    getValidationClass (fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    validateAndSubmit () {
      this.$v.$touch()
      this.wrongCredentials = false

      if (!this.$v.$invalid) {
        this.submit()
      }
    },

    clearForm () {
      this.$v.$reset()
      this.userId = ''
      this.password = ''
    },

    submit () {
      this.sending = true
      this.wrongCredentials = false
      axios.post('/api/v1/sessions', {
        username: this.userId,
        password: this.password
      }).then(response => {
        if (response.data.success) {
          this.$store.commit('setJwtToken', response.data.token_set.token)
          this.$store.commit('setExpiresAt', response.data.token_set.expires_at_unix)

          this.$router.push({ name: 'signedin' })
          this.sending = false
        } else {
          console.error(response.data.error)
        }
      }).catch(err => {
        console.error(err)
        if (err.response.data && (err.response.data.error === 'record not found' ||
          err.response.data.error === 'Invalid password')) {
          this.wrongCredentials = true
        }

        this.sending = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.md-card {
  margin-left: auto;
  margin-right: auto;
}

form {
  margin-top: 3rem;
}
</style>
