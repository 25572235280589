import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    signedIn: false,
    jwtToken: null,
    expiresAt: null
  },

  mutations: {
    setSignedIn (state, value) {
      state.signedIn = value
    },

    setJwtToken (state, value) {
      state.jwtToken = value
      window.$cookies.set('jwtToken', value, '1d')
    },

    setExpiresAt (state, value) {
      state.expiresAt = value
      window.$cookies.set('jwtExpiry', value, '1d')
    },

    clearJwtToken (state) {
      state.jwtToken = null
      window.$cookies.remove('jwtToken')
      window.$cookies.remove('jwtExpiry')
    }
  },

  getters: {
    jwtToken: state => {
      return state.jwtToken || window.$cookies.get('jwtToken')
    },

    expiresAt: state => {
      return state.expiresAt || window.$cookies.get('jwtExpiry')
    }
  }
})
