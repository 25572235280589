<template>
  <div>
    <form novalidate class="md-layout" @submit.prevent="validateAndSubmit">
      <md-card class="md-layout-item md-size-30 md-small-size-100">
        <md-card-header>
          <div class="md-title">Change Password</div>
          <div class="md-content">After changing password, there is a 1 month grace period to update all JWT tokens usages to the newly generated token.</div>
        </md-card-header>

        <md-card-content>
          <!-- Old Password -->
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('oldPassword')">
                <label for="old-password">Old Password</label>
                <md-input
                  name="old-password"
                  id="old-password"
                  type="password"
                  v-model="oldPassword"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.oldPassword.required">Old password is required</span>
              </md-field>
            </div>
          </div>

          <!-- New Password -->
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('newPassword')">
                <label for="new-password">New Password</label>
                <md-input
                  name="new-password"
                  id="new-password"
                  type="password"
                  v-model="newPassword"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.newPassword.required">New password is required</span>
              </md-field>
            </div>
          </div>

          <!-- Confirm New Password -->
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('newPasswordConfirmation')">
                <label for="new-password-confirmation">Confirm New Password</label>
                <md-input
                  name="new-password-confirmation"
                  id="new-password-confirmation"
                  type="password"
                  v-model="newPasswordConfirmation"
                  :disabled="sending"
                />
                <span class="md-error" v-if="!$v.newPasswordConfirmation.required">New Password is required</span>
              </md-field>
            </div>
          </div>
        </md-card-content>

        <md-progress-bar md-mode="indeterminate" v-if="sending"/>

        <md-card-actions>
          <md-button type="submit" class="md-primary" :disabled="sending">Submit</md-button>
        </md-card-actions>
      </md-card>

      <md-snackbar :md-active.sync="success">Password changed successfully. JWT Token above updated.</md-snackbar>
      <md-snackbar :md-active.sync="error">{{errorMsg}}</md-snackbar>
    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'

export default {
  name: 'change-password',
  mixins: [validationMixin],

  data: () => ({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    sending: false,
    success: false,

    error: false,
    errorMsg: ''
  }),

  validations: {
    oldPassword: {
      required
    },
    newPassword: {
      required
    },
    newPasswordConfirmation: {
      required
    }
  },

  computed: {
    ...mapGetters([
      'jwtToken'
    ])
  },

  methods: {
    getValidationClass (fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    validateAndSubmit () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.submit()
      }
    },

    clearForm () {
      this.$v.$reset()
      this.oldPassword = ''
      this.newPassword = ''
      this.newPasswordConfirmation = ''
    },

    submit () {
      this.sending = true
      this.error = false
      this.success = false

      axios.post('/api/v1/change_password', {
        old_password: this.oldPassword,
        new_password: this.newPassword,
        confirm_new_password: this.newPasswordConfirmation
      }, {
        headers: {
          Authorization: 'Bearer ' + this.jwtToken
        }
      }).then(response => {
        if (response.data.success) {
          this.$store.commit('setJwtToken', response.data.token_set.token)
          this.$store.commit('setExpiresAt', response.data.token_set.expires_at_unix)
        }

        this.success = true
        this.clearForm()
        this.sending = false
      }).catch(err => {
        console.error(err)
        if (err.response.data) {
          this.error = true
          this.errorMsg = err.response.data.error
        }

        this.sending = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.md-card {
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-top: 3rem;
}

.md-content {
  margin-top: 1rem;
}
</style>
