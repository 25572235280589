<template>
  <div>
    <a class="logout" href="javascript:void(0)" @click="logout">Logout</a>
    <br/>
    <br/>
    <br/>
    <div class="md-layout">
      <md-card class="md-layout-item md-size-80 md-small-size-100">
        <md-card-header>
          <div class="md-title">JWT Token</div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item md-small-size-100">
            <div class="token-display md-layout-item">{{jwtToken}}</div>
          </div>
          <br/>
          <div class="expire-at">
            <div class="md-layout-item"><b>Expires At:</b></div>
            <div class="token-expiry md-layout-item">{{tokenExpiry}}</div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <br/>
    <br/>
    <change-password/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ChangePassword from '../components/ChangePassword.vue'

export default {
  components: {
    ChangePassword
  },

  computed: {
    ...mapGetters([
      'jwtToken'
    ]),

    tokenExpiry () {
      if (this.$store.getters.expiresAt) {
        return new Date(this.$store.getters.expiresAt * 1000)
      }
      return null
    }
  },

  methods: {
    logout () {
      this.$store.commit('clearJwtToken')
      this.$router.push({ name: 'signedout' })
    }
  }
}
</script>

<style lang="scss" scoped>
.md-card {
  margin-left: auto;
  margin-right: auto;
}

.token-display {
   word-break: break-all;
   word-wrap: break-word;
}

.token-expiry {
  margin-top: 0.1rem;
}
</style>
